import classNames from 'classnames';
import React, { FC } from 'react';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import { toggleContainer, toggle, toggleButton } from './styles';

type Props = {
  isOpen: boolean;
  onToggle: () => void;
  className?: string;
  showMoreText?: string;
  showLessText?: string;
};

export const BuExpandPill: FC<Props> = ({
  isOpen,
  onToggle,
  className,
  showMoreText = 'Show more',
  showLessText = 'Show less',
}) => {
  return (
    <div className={classNames(toggleContainer, className)}>
      <div
        onClick={onToggle}
        className={toggle}
        data-testing="expand-pill-toggle"
      >
        <button className={toggleButton}>
          {isOpen ? showLessText : showMoreText}
          <BuIcon
            color="var(--bu-primary-500)"
            name={isOpen ? BoostUpIcons.ChevronUp : BoostUpIcons.ChevronDown}
          />
        </button>
      </div>
    </div>
  );
};
