import { h2Markdown, h3Markdown, ulMarkdown } from './styles';

interface H2Props {
  children: React.ReactNode;
}

export const H2 = ({ children, ...props }: H2Props) => {
  return (
    <h2 {...props} className={h2Markdown}>
      {children}
    </h2>
  );
};

interface H3Props {
  children: React.ReactNode;
}

export const H3 = ({ children, ...props }: H3Props) => {
  return (
    <h3 {...props} className={h3Markdown}>
      {children}
    </h3>
  );
};

interface UlProps {
  children: React.ReactNode;
}

export const Ul = ({ children, ...props }: UlProps) => {
  return (
    <ul {...props} className={ulMarkdown}>
      {children}
    </ul>
  );
};
