import { useQuery, useMutation } from '@tanstack/react-query';
import { getDealSummary, GetDealSummaryResponse } from 'api/Deals';
import { useState } from 'react';
import { Feedback } from 'contexts/AIChatContext';

const QUERY_KEY = 'dealSummary';

export interface UseDealSummaryResult {
  short: string;
  long: string;
  nextSteps: string[];
  isLoading: boolean;
  isError: boolean;
  isRefreshing: boolean;
  isRefreshSuccess: boolean;
  updatedDate: string;
  feedback?: {
    sentiment?: Feedback;
  };
  isSendingFeedback: boolean;
  isStale: boolean;
  refreshSummary: () => void;
  refreshSummaryInitial: () => void;
  onFeedback: (feedback: Feedback) => void;
}

/**
 * Hook to manage deal summaries with manual refresh handling
 *
 * This hook implements a two-phase loading strategy:
 * 1. Initial Load:
 *    - Fetches summaries with refresh=false
 *    - Returns immediately with cached data if available
 *    - Exposes whether summaries are stale (older than 24h)
 *
 * 2. Refresh Phase (now manual):
 *    - User can manually trigger a refresh through the refreshSummary function
 *    - Maintains the old summary while the new one is being generated
 *    - Updates the UI once the fresh summary is available
 *
 * The hook keeps track of loading and success states separately for both
 * initial load and refresh operations, allowing the UI to show appropriate
 * loading states and update indicators.
 *
 */
export const useDealSummary = (dealId?: string): UseDealSummaryResult => {
  const [feedback, setFeedback] = useState<{ sentiment?: Feedback }>({});
  const [isStale, setIsStale] = useState(false);

  const {
    data: summaryInitial,
    isLoading: isSummaryInitialLoading,
    error: summaryInitialError,
    refetch: refreshSummaryInitial,
  } = useQuery({
    queryKey: [QUERY_KEY, dealId, 'initial'],
    queryFn: () => {
      if (!dealId) return null;
      return getDealSummary(dealId, { refresh: false });
    },
    onSuccess: (data: GetDealSummaryResponse) => {
      setIsStale(data.stale);
    },
    enabled: !!dealId,
  });

  const {
    data: summaryRefreshed,
    isFetching: isNewSummaryLoading,
    isSuccess: isNewSummarySuccess,
    error: summaryRefreshedError,
    refetch: refreshSummary,
  } = useQuery({
    queryKey: [QUERY_KEY, dealId, 'refreshed'],
    queryFn: () => {
      if (!dealId) return null;
      return getDealSummary(dealId, { refresh: true });
    },
    onSuccess: (data: GetDealSummaryResponse) => {
      setIsStale(data.stale);
    },
    enabled: false,
  });

  // Mock mutation for sending feedback - this will need to be replaced with a real API call
  const { mutate: sendFeedback, isLoading: isSendingFeedback } = useMutation({
    mutationFn: async (sentiment: Feedback) => {
      // This should be replaced with an actual API call
      console.log('Sending feedback for deal summary', {
        dealId,
        sentiment,
      });
      // Mock success response after a delay
      await new Promise((resolve) => setTimeout(resolve, 500));
      return { success: true };
    },
    onSuccess: (_, sentiment: Feedback) => {
      setFeedback({ sentiment });
    },
  });

  const handleFeedback = (sentiment: Feedback) => {
    if (sentiment === feedback.sentiment) {
      sendFeedback('neutral');
    } else {
      sendFeedback(sentiment);
    }
  };

  return {
    short:
      summaryRefreshed?.short_summary || summaryInitial?.short_summary || '',
    long: summaryRefreshed?.long_summary || summaryInitial?.long_summary || '',
    nextSteps: summaryRefreshed?.next_steps || summaryInitial?.next_steps || [],
    isLoading: isSummaryInitialLoading,
    isError: !!summaryInitialError || !!summaryRefreshedError,
    isRefreshing: isNewSummaryLoading,
    isRefreshSuccess: isNewSummarySuccess,
    updatedDate:
      summaryRefreshed?.last_updated || summaryInitial?.last_updated || '',
    feedback,
    isSendingFeedback,
    isStale,
    refreshSummary,
    refreshSummaryInitial,
    onFeedback: handleFeedback,
  };
};
