import { FC, useMemo } from 'react';
import moment from 'moment';
import Markdown from 'markdown-to-jsx';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import { BuTag } from 'components/UI/BuTag';
import { H2, H3, Ul } from 'components/deal/DealSummary/SummarMarkdownElements';
// import AIFeedback from 'components/UI/AIFeedback';

import { UseDealSummaryResult } from './useDealSummary';
import DealSummaryCardSkeleton from './DealSummaryCardSkeleton';
import {
  container,
  header,
  title,
  aiNotice,
  content,
  summary,
  lastUpdate,
  aiStatusContainer,
  aiTextContainer,
  statusAndUpdateContainer,
  // feedbackContainer,
  toggleViewButton,
  refreshButton,
  summaryUpdatedAnimation,
  nextStepsContainer,
  h2Markdown,
  ulMarkdown,
} from './styles';

interface Props {
  summary: UseDealSummaryResult;
  isLongView: boolean;
  onToggleView: () => void;
}

const DealSummaryCard: FC<Props> = ({
  summary: {
    short,
    long,
    nextSteps,
    isLoading,
    isError,
    isRefreshing,
    isRefreshSuccess,
    updatedDate,
    // feedback,
    // isSendingFeedback,
    isStale,
    refreshSummary,
    refreshSummaryInitial,
    // onFeedback,
  },
  isLongView,
  onToggleView,
}) => {
  const formattedDate = useMemo(
    () => moment(updatedDate).fromNow(),
    [updatedDate]
  );

  return (
    <div className={container}>
      <div className={header}>
        <h2 className={title}>Deal Summary</h2>
        <div className={aiNotice}>
          {!isLoading && !isError && (
            <div className={aiTextContainer}>
              <BuIcon name={BoostUpIcons.AiIcon} />
              <span>AI has summarized where this deal is right now</span>
            </div>
          )}

          <div className={statusAndUpdateContainer}>
            <div className={aiStatusContainer}>
              {isRefreshing && (
                <BuTag
                  label="Updating summary"
                  className="updating-summary"
                  prefixIcon={<BuIcon name={BoostUpIcons.Reload} />}
                />
              )}

              {isRefreshSuccess && (
                <BuTag
                  label="Summary updated"
                  className={`summary-updated ${summaryUpdatedAnimation}`}
                  prefixIcon={<BuIcon name={BoostUpIcons.Checked} />}
                />
              )}

              {isStale && !isRefreshing && (
                <>
                  <BuTag
                    label="New info available"
                    className="new-info-available"
                  />

                  <BuButton
                    className={refreshButton}
                    onClick={refreshSummary}
                    disabled={isRefreshing}
                    secondary
                  >
                    <BuIcon name={BoostUpIcons.Reload} /> Refresh
                  </BuButton>
                </>
              )}
            </div>
            {updatedDate && (
              <div className={lastUpdate}>Last updated {formattedDate}</div>
            )}
          </div>
        </div>
      </div>
      <div className={content}>
        {isLoading ? (
          <DealSummaryCardSkeleton isLong={isLongView} />
        ) : isError ? (
          <div className={summary}>
            <span>
              Sorry, we were unable to load the deal summary. Please try again.
            </span>
          </div>
        ) : (
          <>
            <Markdown
              className={summary}
              options={{
                overrides: {
                  h2: {
                    component: H2,
                  },
                  h3: {
                    component: H3,
                  },
                  ul: {
                    component: Ul,
                  },
                },
              }}
            >
              {isLongView ? long : short}
            </Markdown>

            {isLongView && nextSteps && nextSteps.length > 0 && (
              <div className={nextStepsContainer}>
                <h2 className={h2Markdown}>Next Steps</h2>

                <ul className={ulMarkdown}>
                  {nextSteps.map((step, index) => (
                    <li key={index}>{step}</li>
                  ))}
                </ul>
              </div>
            )}

            {/* TODO: Add feedback once BE is ready */}
            {/* <div className={feedbackContainer}>
              <AIFeedback
                feedback={feedback}
                isSending={isSendingFeedback}
                onFeedback={onFeedback}
              />
            </div> */}
          </>
        )}

        {isError ? (
          <BuButton
            secondary
            onClick={refreshSummaryInitial}
            className={toggleViewButton}
          >
            <BuIcon name={BoostUpIcons.Reload} /> Retry loading summary
          </BuButton>
        ) : (
          !isLoading && (
            <BuButton
              secondary
              onClick={onToggleView}
              className={toggleViewButton}
            >
              {isLongView ? 'View short summary' : 'View detailed summary'}
            </BuButton>
          )
        )}
      </div>
    </div>
  );
};

export default DealSummaryCard;
