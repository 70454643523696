import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Markdown from 'markdown-to-jsx';
import { useSelector } from 'react-redux';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import aiLoading1 from 'assets/fonts/boostup-icons/ai_loading_1.svg';
import aiLoading2 from 'assets/fonts/boostup-icons/ai_loading_2.svg';
import aiLoading3 from 'assets/fonts/boostup-icons/ai_loading_3.svg';
import { useAIChat } from 'contexts/AIChatContext';
import BuIcon from 'components/UI/BuIcon';
import BuButton from 'components/UI/BuButton';
import { Ol } from 'components/UI/AiMarkdownElements/AiMarkdownElements';
import Tooltip from 'components/UI/common/Tooltip';
import { H2, Ul } from 'components/UI/AiMarkdownElements';
import AIFeedback from 'components/UI/AIFeedback';
import { AI_CHAT_WELCOME_TEMPLATES } from 'common/constants';
import { getUser } from 'selectors';

import * as s from './styles';

const AIChat: React.FC = () => {
  const {
    pageContext,
    messages,
    setHideChat,
    isLoadingHistory,
    isSendingMessage,
    sendMessage,
    sendFeedback,
    isSendingFeedback,
  } = useAIChat();

  const [inputValue, setInputValue] = useState('');
  const [copyStates, setCopyStates] = useState<Record<string, boolean>>({});
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const user = useSelector(getUser);

  const userName = user?.name;

  useEffect(() => {
    scrollToBottom();
  }, [messages, isSendingMessage]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [inputValue]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSubmit = async (text: string) => {
    if (!text.trim()) return;
    setInputValue('');
    await sendMessage(text);
  };

  const handleSuggestionClick = (suggestion: string) => {
    handleSubmit(suggestion);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(inputValue);
    }
  };

  const handleClickCopyToClipboard = (messageId: string, text: string) => {
    navigator.clipboard.writeText(text);

    // Set success state for this message
    setCopyStates({ ...copyStates, [messageId]: true });

    // Reset after 2 seconds
    setTimeout(() => {
      setCopyStates((prev) => ({ ...prev, [messageId]: false }));
    }, 2000);
  };

  // Get welcome message based on context type and replace {id} placeholder
  const getWelcomeMessage = () => {
    let template = AI_CHAT_WELCOME_TEMPLATES.DEFAULT;

    switch (pageContext.object_type) {
      case 'call':
        template = AI_CHAT_WELCOME_TEMPLATES.CALL;
        break;
      case 'deal':
        template = AI_CHAT_WELCOME_TEMPLATES.DEAL;
        break;
    }

    return template.replace('{name}', userName);
  };

  return (
    <div className={s.chatContainer}>
      <div className={s.header}>
        <BuIcon
          className={s.aiIcon}
          name={BoostUpIcons.AiIconFilled}
          color="var(--bu-primary-700)"
        />
        <h3 className={s.headerTitle}>BoostBot</h3>
        <button className={s.closeButton} onClick={() => setHideChat(true)}>
          <BuIcon
            className={s.closeIcon}
            name={BoostUpIcons.CloseBig}
            color="var(--bu-gray-800)"
          />
        </button>
      </div>

      <div className={s.messagesContainer}>
        {isLoadingHistory ? (
          <div className={s.aiMessageContainer}></div>
        ) : messages.length === 0 && !isLoadingHistory ? (
          <>
            <div className={s.aiMessageContainer}>
              <div className={classNames(s.aiMessageText, 'column')}>
                {getWelcomeMessage()}
                <div className={s.aiMessageTextBelow}>
                  Below are some suggestions of things I can help with:
                </div>
              </div>
            </div>
            {pageContext.suggestedActions.map(
              (suggestion: string, index: number) => (
                <button
                  key={index}
                  className={s.suggestionButton}
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  <span className={s.suggestionText}>{suggestion}</span>
                </button>
              )
            )}
          </>
        ) : (
          messages.map((message, index) => {
            const feedback = message.feedback;

            return message.sender === 'user' ? (
              <div key={index} className={s.messageContainer}>
                <div className={s.userMessageBubble}>
                  <BuIcon
                    className={s.userMessageIcon}
                    name={BoostUpIcons.User}
                    color="var(--bu-gray-900)"
                  />
                  <div className={s.userMessageText}>{message.message}</div>
                </div>
              </div>
            ) : (
              <div
                key={index}
                className={classNames(s.messageContainer, s.aiMessageContainer)}
              >
                <div className={classNames(s.aiMessageText, 'column')}>
                  <Markdown
                    options={{
                      overrides: {
                        h2: {
                          component: H2,
                        },
                        ul: {
                          component: Ul,
                        },
                        ol: {
                          component: Ol,
                        },
                      },
                    }}
                  >
                    {message.message}
                  </Markdown>

                  {message.id && (
                    <div className={s.actionsContainer}>
                      <Tooltip
                        tooltip="Copy to clipboard"
                        position="top center"
                        mouseEnterDelay={300}
                      >
                        <div className={s.copyButton}>
                          <BuButton
                            secondary
                            borderless
                            onClick={() =>
                              handleClickCopyToClipboard(
                                message.id as string,
                                message.message
                              )
                            }
                          >
                            <BuIcon
                              name={
                                copyStates[message.id as string]
                                  ? BoostUpIcons.CheckCircle
                                  : BoostUpIcons.Copy
                              }
                              color="var(--bu-gray-900)"
                            />
                          </BuButton>
                        </div>
                      </Tooltip>

                      <AIFeedback
                        feedback={message.feedback}
                        isSending={isSendingFeedback}
                        onFeedback={(sentiment) =>
                          sendFeedback({
                            messageId: message.id as string,
                            feedback: sentiment,
                          })
                        }
                      />
                    </div>
                  )}

                  {message.follow_up_questions &&
                    message.follow_up_questions.length > 0 &&
                    index === messages.length - 1 && (
                      <div className={s.followUpQuestionsContainer}>
                        <div className={s.followUpButtonsContainer}>
                          {message.follow_up_questions.map(
                            (question, qIndex) => (
                              <button
                                key={qIndex}
                                className={s.suggestionButton}
                                onClick={() => handleSuggestionClick(question)}
                              >
                                <span className={s.suggestionText}>
                                  {question}
                                </span>
                              </button>
                            )
                          )}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            );
          })
        )}

        {isSendingMessage && (
          <div className={s.aiMessageContainer}>
            <div className={s.aiMessageText}>
              <div className={s.loadingIconContainer}>
                <img src={aiLoading1} alt="AI thinking" />
                <img src={aiLoading2} alt="AI thinking" />
                <img src={aiLoading3} alt="AI thinking" />
              </div>
              <span className={s.thinkingText}>
                <span className="text" />
                <span className={s.dots}></span>
              </span>
            </div>
          </div>
        )}

        <div ref={messagesEndRef} />
      </div>

      <div className={s.inputContainer}>
        <textarea
          ref={textareaRef}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyPress}
          placeholder="Type your message..."
          className={s.input}
          disabled={isLoadingHistory || isSendingMessage}
        />
        <button
          className={s.sendButton}
          onClick={() => handleSubmit(inputValue)}
          disabled={isLoadingHistory || isSendingMessage}
        >
          <BuIcon
            className={s.sendIcon}
            name={BoostUpIcons.ArrowRight}
            color="var(--bu-white)"
          />
        </button>
      </div>
    </div>
  );
};

export default AIChat;
