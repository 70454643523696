import { FC, useState } from 'react';
import DealSummaryCard from './DealSummaryCard';
import { useDealSummary } from './useDealSummary';

interface Props {
  dealId?: string;
}

const DealSummary: FC<Props> = ({ dealId }) => {
  const [isLongView, setIsLongView] = useState(false);
  const summary = useDealSummary(dealId);

  const handleToggleView = () => setIsLongView(!isLongView);

  return (
    <DealSummaryCard
      summary={summary}
      isLongView={isLongView}
      onToggleView={handleToggleView}
    />
  );
};

export default DealSummary;
