import { css } from 'emotion';

export const feedbackContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--bu-control-gap-medium);
  width: fit-content;
  align-self: flex-end;

  &.disabled {
    opacity: 0.5;
  }

  button {
    margin: 0;
    padding: 0;
    padding: 0 6px;
    background-color: var(--bu-primary-200) !important;
    border-radius: var(--bu-control-border-radius);

    i {
      color: var(--bu-gray-700) !important;
      font-size: 20px;
    }

    &.active {
      &.positive {
        background-color: var(--bu-green-500) !important;

        i {
          color: var(--bu-white) !important;
        }
      }

      &.negative {
        background-color: var(--bu-red-500) !important;

        i {
          color: var(--bu-white) !important;
        }
      }
    }
  }
`;
