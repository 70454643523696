import React from 'react';
import moment from 'moment';
import { Link, ModalLink } from 'navigation/Link';
import * as styles from './styles';
import { RiskScore } from 'components/UI/common/TypedTable/renderers/RiskScoreCell';
import { Popup } from 'semantic-ui-react';
import truncate from 'lodash/truncate';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';

interface FixedDealFieldsProps {
  amount: string;
  engagementRisk?: {
    value: number;
    riskFactors: Deals.RiskFactor[];
    positiveIndicators: Deals.PositiveIndicator[];
    activitySyncInfo: Deals.ActivitySyncInfoRecord[];
  };
  closeDate: string;
  nextMeeting?: {
    start: string;
    event_id: string;
  } | null;
  lastEngagement?: string;
  account: {
    id: string;
    name: string;
  };
  accountExecutive: string;
  isPanel?: boolean;
}

const FixedDealFields: React.FC<FixedDealFieldsProps> = ({
  amount,
  engagementRisk,
  closeDate,
  nextMeeting,
  lastEngagement,
  account,
  accountExecutive,
  isPanel,
}) => {
  const renderAccountLink = () => {
    const panelUrl = {
      scheme: '/accounts/preview/:id',
      params: { id: account.id },
    } as const;

    const fullScreenUrl = {
      scheme: '/accounts/preview/:id',
      params: { id: account.id },
    } as const;

    return (
      <Link url={isPanel ? panelUrl : fullScreenUrl}>
        <TooltipWrapper
          tooltip={<p>{account.name}</p>}
          position="bottom center"
        >
          <div className={styles.linkValue}>{account.name}</div>
        </TooltipWrapper>
      </Link>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.field}>
        <span className={styles.label}>Amount</span>
        <div className={styles.value}>{amount}</div>
      </div>

      {engagementRisk && (
        <div className={styles.field} onClick={(e) => e.stopPropagation()}>
          <span className={styles.label}>Engagement risk</span>
          <RiskScore
            value={engagementRisk.value}
            riskFactors={engagementRisk.riskFactors}
            positiveIndicators={engagementRisk.positiveIndicators}
            activitySyncInfo={engagementRisk.activitySyncInfo}
            isProgression={false}
          />
        </div>
      )}

      <div className={styles.field}>
        <span className={styles.label}>Close Date</span>
        <div className={styles.value}>
          {moment(closeDate).format('MMM D, YYYY')}
        </div>
      </div>

      <div className={styles.field}>
        <span className={styles.label}>Next Meeting</span>
        {!nextMeeting ? (
          <span className={styles.value}>Not scheduled</span>
        ) : (
          <ModalLink
            url={{
              scheme: '/event/:eventId',
              params: { eventId: nextMeeting.event_id },
            }}
          >
            <div className={styles.linkValue}>
              {moment(nextMeeting.start).format('MMM D, YYYY')}
            </div>
          </ModalLink>
        )}
      </div>

      {lastEngagement && (
        <div className={styles.field}>
          <span className={styles.label}>Last Engagement</span>
          <div className={styles.value}>{lastEngagement}</div>
        </div>
      )}

      <div className={styles.field}>
        <span className={styles.label}>Account</span>
        {renderAccountLink()}
      </div>

      <div className={styles.field}>
        <span className={styles.label}>AE</span>
        <TooltipWrapper
          tooltip={<p>{accountExecutive}</p>}
          position="bottom center"
        >
          <div className={styles.value}>{accountExecutive}</div>
        </TooltipWrapper>
      </div>
    </div>
  );
};

export default FixedDealFields;
