import { css } from 'emotion';

export const container = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  gap: var(--bu-control-gap-medium);
  background: var(--bu-primary-100);
  border: 1px solid var(--bu-gray-400);
  border-radius: var(--bu-control-border-radius);
  width: 100%;
`;

export const header = css`
  display: flex;
  align-items: center;
  gap: var(--bu-control-gap-xx-large);
  width: 100%;
  height: 32px;
`;

export const title = css`
  font-family: var(--bu-font-bold);
  font-size: 16px;
  line-height: 140%;
  color: var(--bu-text-primary);
  margin: 0;
`;

export const aiNotice = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: var(--bu-control-gap-medium);
  font-family: var(--bu-font-medium);
  font-size: 14px;
  line-height: 140%;
  color: var(--bu-primary-700);
  flex: 1;
`;

export const statusAndUpdateContainer = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

export const aiStatusContainer = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 6px 0;
  gap: var(--bu-control-gap-medium);

  button {
    margin: 0;
  }

  .label-tag {
    &.updating-summary {
      color: var(--bu-yellow-700);
      background-color: var(--bu-yellow-300);
    }

    &.summary-updated,
    &.new-info-available {
      color: var(--bu-green-700);
      background-color: var(--bu-green-300);
    }
  }
`;

export const aiTextContainer = css`
  display: flex;
  align-items: center;
  gap: var(--bu-control-gap-small);
`;

export const lastUpdate = css`
  font-family: var(--bu-font-medium);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: var(--bu-text-gray);
`;

export const content = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--bu-text-primary);
  gap: var(--bu-control-gap-large);
  width: 100%;
`;

export const summary = css`
  font-size: 16px;
  line-height: 22.4px;
  text-align: left;
  color: var(--bu-text-primary);
  margin: 0;
  max-width: 920px;
`;

export const h2Markdown = css`
  font-family: var(--bu-font-bold);
  font-size: 16px;
  line-height: 22.4px;
  text-align: left;
  color: var(--bu-text-primary);
`;

export const h3Markdown = css`
  ${h2Markdown}
`;

export const ulMarkdown = css`
  padding-left: 20px;
  font-size: 16px;
  line-height: 22.4px;
  text-align: left;
  color: var(--bu-text-primary);
  margin-bottom: 0;

  li {
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const feedbackContainer = css`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 8px;
`;

export const toggleViewButton = css`
  margin: 0;
`;

export const refreshButton = css`
  margin-left: 10px;
`;

export const summaryUpdatedAnimation = css`
  @keyframes fadeInOut {
    0% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  animation: fadeInOut 3s forwards;
`;

export const nextStepsContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 16px;
`;
