import { isArray, isNil } from 'lodash';
import React, { FC } from 'react';

import InlineOption from 'components/UI/InlineDropDownOption/InlineDropDownOption';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';

type Props = {
  isBool?: boolean;
  field: string;
  fieldDropdown: string;
  valueRaw: string | number | boolean | object;
  multiple: boolean;
  onChange: (value: string) => void;
  isEditDisabled: (value: string) => boolean;
};

export const DropdownOrText: FC<Props> = ({
  isBool: isBooleanField = false,
  field,
  fieldDropdown,
  valueRaw,
  multiple,
  onChange,
  isEditDisabled,
}) => {
  const value = !isNil(valueRaw) ? valueRaw : 'Not Available';

  const displayValue = isArray(value)
    ? value.join(', ')
    : isBooleanField
    ? Boolean(valueRaw)
      ? 'Yes'
      : 'No'
    : value;

  return isEditDisabled(field) ? (
    <TooltipWrapper tooltip={<p>{displayValue}</p>} position="bottom center">
      <div className="deal-header-tabs_item_label">{displayValue}</div>
    </TooltipWrapper>
  ) : (
    <InlineOption
      isBooleanField={isBooleanField}
      value={value.toString()}
      field={fieldDropdown}
      multiple={multiple}
      onChange={onChange}
    />
  );
};
