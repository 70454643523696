import classNames from 'classnames';
import truncate from 'lodash/truncate';
import moment from 'moment';

import { isNil, pathOr, sortWith, descend, prop } from 'ramda';
import React, { FC, useState, useEffect, Fragment } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast, ToastOptions } from 'react-toastify';
import { Loader, Popup } from 'semantic-ui-react';

import { activityRemapRequest } from 'actions/activityActions';
import * as dealsActions from 'actions/dealsActions';
import { goBackOr, goToAllDeals } from 'actions/routeActions';
import { getUserProfileDisplayConfiguration } from 'actions/settingsActions';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import { DEFAULT_EDITABLE_FIELDS, AI_CHAT_ACTIONS } from 'common/constants';
import { notNullAndEmpty } from 'common/utils';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import ColumnPopupTable from 'components/UI/ColumnPopupTable';
import EditableTextBox from 'components/UI/EditableTextBox/EditableTextBox';
import BuyerEngagement from 'components/UI/EngagementTable';
import InlineDropDownDate from 'components/UI/InlineDropDownDate/InlineDropDownDate';
import MeetingsCalls from 'components/UI/MeetingAndCallsTable';
import MomentsTabs from 'components/UI/MomentsTabs';
import ProspectEngagement from 'components/UI/ProspectEngagement';
import RiskScoreBlock from 'components/UI/RiskScoreBlock';
import TopicsTabs from 'components/UI/TopicsDiscussedTabs';
import Truncate from 'components/UI/TruncatedText';
import { ValueProp } from 'components/UI/common/TypedTable/TypedTable';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import DealInsightsSkeleton from 'components/deal/DealInsightsSkeleton';
import { DebugPanel } from 'components/deal/DebugPanel';
import { DropdownOrText } from 'components/deal/DropdownOrText';
import { ExtraFields } from 'components/deal/ExtraFields';
import { MoneyField } from 'components/deal/MoneyField';
import FixedDealFields from './FixedDealFields';
// import { PredictionPanel } from 'components/deal/PredictionPanel';
import 'components/deal/style.css';
import styles from 'components/deal/styles';
import { Props, IOwnProps, IProps } from 'components/deal/types';
import { VirtualizedItem } from 'components/virtualized/VirtualizedItem';
import { ModalLink, Link } from 'navigation/Link';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';
import { useAIChat } from 'contexts/AIChatContext';
import DealSummary from './DealSummary/DealSummary';
import { currencyFormatter } from 'components/UI/common/TypedTable/formatters';
import { BuExpandPill } from 'components/UI/common/BuExpandPill/BuExpandPill';

const toastOptions: ToastOptions = { position: 'bottom-left' };

const DealInsights: FC<Props> = (props) => {
  const [isPanelOpen, setPanelOpen] = useState<boolean>(false);
  const [isFieldsVisible, setIsFieldsVisible] = useState<boolean>(false);

  const {
    companyCurrency,
    allowProcessEmailBody,
    deal,
    dealId,
    defaultTimeFilter,
    extraColumns,
    hiddenColumns,
    isPanel,
    featureFlags,
    statuses,
    previousRoute,
    editableFields,
    isMulticurrencyEnabled,
    match,
    location,
    remapActivityStatus,
    isAdmin,
    isAdminSFDCUpdateAllowed,
    frozenTime,
    isReadOnlyUser,
    isAdminEnabled,
    isFullScreen,
    dealsInsightsColumns,
  } = props;

  const dispatch = useDispatch();

  const describeFields = () => dispatch(dealsActions.describeFields());

  const dealsInsightConfig = () =>
    dispatch(getUserProfileDisplayConfiguration());

  const getDeal = (id: string) => dispatch(dealsActions.getDealDetailes(id));

  const goBack = (previousRoute: string) =>
    dispatch(goBackOr(goToAllDeals, previousRoute));

  const remapActivity = (payload: { deal_id: string }) =>
    dispatch(activityRemapRequest(payload));

  const syncDeal = (id: string) => dispatch(dealsActions.syncDeal(id));

  const changeDeal = (id: string, changes: Record<string, ValueProp>) => {
    if (isAdmin && !isAdminSFDCUpdateAllowed) {
      toast.error('Admin not allowed.', {
        position: 'bottom-left',
      });
      return null;
    }
    return dispatch(dealsActions.updateDealDetail(id, changes));
  };

  const { enabled: isChatEnabled, setEnabled, setPageContext } = useAIChat();

  useEffect(() => {
    const _dealId = match?.params?.dealId || dealId;

    // && false because right know we will not support the chat bot inside the call details view, only on calls
    if (_dealId && false) {
      setPageContext({
        object_type: 'deal',
        object_id: _dealId,
        suggestedActions: AI_CHAT_ACTIONS.DEAL,
      });

      setEnabled(true);

      return () => {
        setEnabled(false);
      };
    }
  }, [isPanel, match?.params?.dealId, setEnabled]);

  useEffect(() => {
    if (dealId && deal.status !== 'success') {
      getDeal(dealId);
    }

    if (
      match?.params?.dealId &&
      deal.data?._id !== match.params.dealId &&
      deal.status !== 'success'
    ) {
      getDeal(dealId || match.params.dealId);
    }

    describeFields();
    dealsInsightConfig();

    AnalyticsTracker.setUserProperties(props);
    AnalyticsTracker.pageview(props);
  }, []);

  useEffect(() => {
    if (
      remapActivityStatus &&
      remapActivityStatus.status === 'success' &&
      remapActivityStatus.message
    ) {
      toast.success(remapActivityStatus.message, toastOptions);
    }
  }, [remapActivityStatus]);

  const nextStepClasses = classNames(styles.description, {
    next_steps_red: isNil(deal?.data?.next_step),
  });

  const dealStatus = statuses?.[deal?.data?._id]?.status;

  const handleGoBack = () => goBack(previousRoute);

  const isEditDisabled = (field: string) => {
    if (isReadOnlyUser) {
      return true;
    }

    if (
      (editableFields.includes('__default__') &&
        DEFAULT_EDITABLE_FIELDS.includes(field.toLowerCase())) ||
      editableFields.includes('__all__')
    ) {
      return false;
    }

    return !editableFields.includes(field.toLowerCase());
  };

  const currency = pathOr(
    companyCurrency,
    ['crm_metadata', 'currency'],
    deal.data
  );

  const lastCall = deal.data?.last_call?.call;

  const lastCallSummary = deal.data?.last_call?.summary;

  const bannerNotSynced = (
    <div className="underHeader" text-align="left">
      <p>
        Warning: The data in this opportunity is not complete because of the
        following reasons.
      </p>
      <ol style={{ paddingLeft: '20px', marginTop: '10px' }}>
        {notNullAndEmpty(deal?.data?.activity_sync_info)
          ? deal.data?.activity_sync_info.map((e) => (
              <li key={e.text}>{e.text}</li>
            ))
          : null}
      </ol>
    </div>
  );

  const lastEngagement =
    deal.data?.last_activity &&
    (frozenTime
      ? moment(deal.data?.last_activity.occurred_at).from(frozenTime)
      : moment(deal.data?.last_activity.occurred_at).fromNow());

  const handleChangeNextSteps = (nextStep: any) => {
    changeDeal(deal.data?._id, {
      next_step: nextStep === '' ? null : nextStep,
    });
  };

  const showEngagementData = Boolean(
    featureFlags.deal_show_opportunity_insights_enabled
  );

  if (deal.status === 'loading' || deal.status === 'notAsked') {
    return <DealInsightsSkeleton />;
  }

  const openUrlNewTab = (url: string | undefined) => {
    const win = window.open(url, '_blank');
    win?.focus();
  };

  return (
    <div className={classNames('wrapper', styles.wrapper)}>
      <div className="container">
        <div className="container-deal">
          <div className="deal-header">
            <div className="deal-header__top-section">
              {!isPanel && !isFullScreen && (
                <div className="deal-header__breadcrumb">
                  <BuButton
                    secondary
                    onClick={handleGoBack}
                    size={BuControlSize.SMALL}
                  >
                    <BuIcon name={BoostUpIcons.ArrowLeft} />
                    <span>Back</span>
                  </BuButton>

                  <div className="deal-header__breadcrumb-info">
                    <Link url={{ scheme: '/opportunities' }}>
                      <span className="deal-header__breadcrumb-info-bold">
                        Opportunities&nbsp;/&nbsp;
                      </span>
                    </Link>
                    Deal details
                  </div>
                </div>
              )}

              {notNullAndEmpty(deal?.data?.activity_sync_info) &&
                bannerNotSynced}

              <div className="deal-header__top">
                <div className="deal-header__top-left">
                  <TooltipWrapper
                    tooltip={<p>{deal?.data?.name}</p>}
                    position="bottom center"
                  >
                    <p className="deal-header-title bu-font-section-title">
                      {deal?.data?.name}
                    </p>
                  </TooltipWrapper>
                </div>

                {!isFullScreen && (
                  <div className="deal-header__top-right">
                    {featureFlags?.allow_single_record_sf_sync && (
                      <div className={styles.SyncDealContainer}>
                        {deal.data?.last_checked && (
                          <span>
                            Last synced on{' '}
                            {moment(deal.data?.last_checked).format(
                              'MMM DD [at] HH:mm a'
                            )}
                          </span>
                        )}
                        <TooltipWrapper tooltip="Sync with CRM" hoverable>
                          <BuButton
                            icon
                            onClick={() => syncDeal(deal.data?._id)}
                            className={styles.syncButton}
                          >
                            {(dealStatus === 'error' ||
                              dealStatus === undefined) && (
                              <BuIcon name={BoostUpIcons.Sync} />
                            )}
                            {dealStatus === 'loading' && (
                              <Loader size="tiny" active inline />
                            )}
                            {dealStatus === 'success' && (
                              <BuIcon name={BoostUpIcons.Checked} />
                            )}
                          </BuButton>
                        </TooltipWrapper>
                      </div>
                    )}

                    {deal?.data?.salesforce_url && (
                      <BuButton
                        onClick={() => openUrlNewTab(deal.data?.salesforce_url)}
                        secondary
                      >
                        Open in Salesforce
                      </BuButton>
                    )}
                    {deal?.data?.hubspot_url && (
                      <BuButton
                        onClick={() => openUrlNewTab(deal.data?.hubspot_url)}
                        secondary
                      >
                        Open in Hubspot
                      </BuButton>
                    )}

                    {/* && false because right know we will not support the chat bot inside the call details view, only on calls */}
                    {!isChatEnabled && false && (
                      <BuButton
                        secondary
                        className={styles.chatButton}
                        onClick={() => setEnabled(true)}
                      >
                        <BuIcon name={BoostUpIcons.AiIcon} />
                        Chatbot
                      </BuButton>
                    )}

                    {!isReadOnlyUser && isAdminEnabled && (
                      <BuButton
                        onClick={() =>
                          remapActivity({ deal_id: deal.data?._id })
                        }
                        secondary
                      >
                        Remap all activity
                      </BuButton>
                    )}

                    {/* {isPanel && (
                      <Link
                        url={{
                          scheme: '/opportunities/preview/:dealId',
                          params: { dealId },
                        }}
                      >
                        <BuIcon
                          className={styles.fullScreenIcon}
                          name={BoostUpIcons.FullScreen}
                        />
                      </Link>
                    )} */}
                  </div>
                )}
              </div>
              <FixedDealFields
                amount={currencyFormatter(
                  companyCurrency,
                  0,
                  isMulticurrencyEnabled
                )(
                  Number(deal.data?.amount?.value),
                  currency,
                  deal.data?.exchange_rate
                )}
                engagementRisk={
                  deal?.data?.risk_score && {
                    value: Number(deal.data?.risk_score?.value || 0),
                    riskFactors: deal.data?.risk_factors || [],
                    positiveIndicators: deal.data?.positive_indicators || [],
                    activitySyncInfo: deal.data?.activity_sync_info || [],
                  }
                }
                closeDate={deal.data?.close_date?.value as string}
                nextMeeting={
                  deal.data?.next_meeting
                    ? {
                        start: deal.data.next_meeting.start,
                        event_id: deal.data.next_meeting.event_id,
                      }
                    : null
                }
                lastEngagement={lastEngagement}
                account={{
                  id: deal.data?.account_id,
                  name: deal.data?.account,
                }}
                accountExecutive={deal.data?.account_executive || ''}
                isPanel={isPanel}
              />

              <div className="deal-header-tabs-wrapper">
                {isFieldsVisible && (
                  <section
                    id="deal-container-header-tabs"
                    className="deal-header-tabs"
                  >
                    {!hiddenColumns.includes('amount') &&
                      deal?.data?._id &&
                      changeDeal && (
                        <MoneyField
                          id={deal.data._id}
                          field="Amount"
                          value={deal.data.amount.value}
                          exchangeRate={deal.data?.exchange_rate}
                          currency={currency}
                          companyCurrency={companyCurrency}
                          isMulticurrencyEnabled={isMulticurrencyEnabled}
                          isEditDisabled={isEditDisabled}
                          changeDeal={changeDeal}
                          accountExecutive={deal.data.account_executive}
                        />
                      )}

                    <ExtraFields
                      deal={deal.data}
                      hiddenColumns={hiddenColumns}
                      companyCurrency={companyCurrency}
                      isMulticurrencyEnabled={isMulticurrencyEnabled}
                      dealsInsightsColumns={dealsInsightsColumns}
                      changeDeal={changeDeal}
                      isEditDisabled={isEditDisabled}
                    />

                    {!hiddenColumns.includes('stage') && (
                      <article
                        id="deal-container-header-tabs-item-stage"
                        className={classNames(
                          'deal-header-tabs_item',
                          isEditDisabled('stage') && styles.disablePointerEvents
                        )}
                      >
                        <span className="deal-header-tabs_item-label">
                          Stage
                        </span>
                        <span className="deal-header-tabs_item--black">
                          {deal?.data?.stage?.value && (
                            <DropdownOrText
                              field="stage"
                              fieldDropdown="opportunity_stage"
                              valueRaw={deal.data?.stage.value}
                              onChange={(value: string) =>
                                changeDeal(deal.data?._id, {
                                  opportunity_stage: value,
                                })
                              }
                              multiple={false}
                              isEditDisabled={isEditDisabled}
                            />
                          )}
                        </span>
                      </article>
                    )}

                    {!hiddenColumns.includes('forecast categories') && (
                      <article
                        id="deal-container-header-tabs-item-forecast-category"
                        className="deal-header-tabs_item"
                      >
                        <span className="deal-header-tabs_item-label">
                          Forecast Category
                        </span>
                        <span
                          className={classNames(
                            'deal-header-tabs_item--black',
                            isEditDisabled('forecast categories') &&
                              styles.disablePointerEvents
                          )}
                        >
                          {deal?.data?.forecast_category_name?.value && (
                            <DropdownOrText
                              field="forecast categories"
                              fieldDropdown="forecast_category"
                              valueRaw={deal.data?.forecast_category_name.value}
                              onChange={(value: string) =>
                                changeDeal(deal.data?._id, {
                                  forecast_category_name: value,
                                })
                              }
                              multiple={false}
                              isEditDisabled={isEditDisabled}
                            />
                          )}
                        </span>
                      </article>
                    )}

                    {!hiddenColumns.includes('close date') && (
                      <article
                        id="deal-container-header-tabs-item-close-date"
                        className="deal-header-tabs_item"
                      >
                        <span className="deal-header-tabs_item-label">
                          Close Date
                        </span>
                        <span
                          className={classNames(
                            'deal-header-tabs_item--black',
                            isEditDisabled('close date') &&
                              styles.disablePointerEvents
                          )}
                        >
                          {deal?.data?.close_date?.value && (
                            <InlineDropDownDate
                              isEditDisabled={isEditDisabled('close date')}
                              value={
                                !isNil(deal.data?.close_date.value)
                                  ? (deal.data?.close_date.value as string)
                                  : 'Not Available'
                              }
                              onChange={(value) =>
                                changeDeal(deal.data?._id, {
                                  close_date: value,
                                })
                              }
                              showIcon={!isEditDisabled('close date')}
                            />
                          )}
                        </span>
                      </article>
                    )}

                    {!hiddenColumns.includes('create date') && (
                      <article
                        id="deal-container-header-tabs-item-create-date"
                        className="deal-header-tabs_item"
                      >
                        <span className="deal-header-tabs_item-label">
                          Create Date
                        </span>
                        <span className="deal-header-tabs_item--black">
                          {deal?.data?.create_date
                            ? moment(
                                deal.data?.created_at,
                                'YYYY-MM-DD'
                              ).format('DD MMM YYYY')
                            : 'Not Available'}
                        </span>
                      </article>
                    )}
                  </section>
                )}
                <div className="deal-header-toggle-container">
                  <div className="deal-header-toggle-line" />
                  <BuExpandPill
                    isOpen={isFieldsVisible}
                    onToggle={() => setIsFieldsVisible(!isFieldsVisible)}
                    showMoreText="Show all fields"
                    showLessText="Hide all fields"
                  />
                </div>
              </div>
            </div>
          </div>

          {featureFlags.deal_summary_enabled && (
            <div className="deal-header-summary-wrapper">
              <DealSummary dealId={dealId || match?.params?.dealId} />
            </div>
          )}

          {deal.data?.contacts && (
            <DebugPanel
              isPanelOpen={isPanelOpen}
              setPanelOpen={setPanelOpen}
              contacts={deal.data?.contacts}
            />
          )}

          {showEngagementData && (
            <div
              id="deal-container-header-info-meeting"
              className="deal-header-info-meeting"
            >
              {lastCall && lastCall.no_show ? (
                <div
                  id="deal-container-header-info-meeting-last-meeting"
                  className="deal-header-info-meeting-item"
                >
                  <span className="deal-header-info-meeting-item-title">
                    Last Meeting:&nbsp;
                  </span>
                  No Show
                </div>
              ) : null}

              {lastCall && !lastCall.no_show ? (
                <Link
                  url={{
                    scheme: '/transcript/:id',
                    params: { id: lastCall },
                  }}
                >
                  <div
                    id="deal-container-header-info-meeting-last-meeting"
                    className="deal-header-info-meeting-item"
                  >
                    <span className="deal-header-info-meeting-item-title">
                      Last Meeting:&nbsp;
                    </span>
                    {lastCallSummary}
                    <BuIcon
                      name={BoostUpIcons.Play}
                      color="var(--bu-color-meeting)"
                    />
                  </div>
                </Link>
              ) : null}

              {!deal?.data?.is_rep_engaged ? (
                <div
                  id="deal-container-header-info-meeting-no-rep-engaged"
                  className="deal-header-info-meeting-item"
                >
                  Rep not engaged yet
                </div>
              ) : null}

              {featureFlags &&
                featureFlags.progression_risk_factors_enabled &&
                deal.data?.progression_risk_factors && (
                  <RiskScoreBlock
                    name="Forecast Risk Score"
                    isProgression
                    activitySyncInfo={[]}
                    riskFactors={deal.data?.progression_risk_factors}
                    positiveIndicators={
                      deal.data?.progression_positive_indicators
                    }
                    score={deal.data?.progression_risk_score}
                  />
                )}
            </div>
          )}

          {showEngagementData && (
            <div className={styles.prospectEngagementContainer}>
              <ProspectEngagement
                title="Prospect Engagement"
                dealId={deal.data?._id}
                timespan={defaultTimeFilter}
              />
            </div>
          )}

          {extraColumns &&
            extraColumns.map((column) => {
              const columnName = column.name;

              let rows =
                deal?.data?.extra && deal?.data?.extra[columnName]
                  ? deal.data?.extra[columnName].objects
                  : [];

              if (column.additional_table && rows?.length > 0) {
                if (columnName === 'Meetings') {
                  rows = sortWith([descend(prop('Meeting_Date__c'))])(rows);
                }

                return (
                  <Fragment key={columnName}>
                    <p className={styles.title}>
                      {column.name} ({deal.data?.extra[column.name].value})
                    </p>

                    <ColumnPopupTable
                      columns={column.additional_table.columns}
                      rows={rows}
                    />
                  </Fragment>
                );
              }
            })}

          {Boolean(showEngagementData && allowProcessEmailBody) && (
            <VirtualizedItem withClone>
              <MomentsTabs
                id={deal.data?._id}
                type="deal"
                timespan={defaultTimeFilter}
              />
            </VirtualizedItem>
          )}

          <div id="deal-container-next-steps" className={styles.next_steps}>
            <div
              className={classNames(
                styles.next_steps_header,
                'bu-font-heading'
              )}
            >
              <BuIcon
                name={BoostUpIcons.TimelineNextSteps}
                className={styles.next_steps_header_icon}
              />
              <div className={styles.next_steps_header_title}>Next Steps</div>
            </div>

            <div className={nextStepClasses}>
              <EditableTextBox
                value={deal.data?.next_step}
                disabled={isReadOnlyUser}
                placeholder="No next steps found"
                onChange={handleChangeNextSteps}
              />
            </div>
          </div>

          <div className="deal-body-container">
            <div className="deal-body__list">
              <div style={{ marginBottom: '20px', width: '100%' }}>
                {showEngagementData && (
                  <div className="callTranscript__buttons-box">
                    <VirtualizedItem withClone>
                      <BuyerEngagement dealId={deal.data?._id} />
                    </VirtualizedItem>
                  </div>
                )}
                {showEngagementData && (
                  <div id="deal-body-meetings-call-container">
                    <VirtualizedItem withClone>
                      <MeetingsCalls
                        objectId={deal.data?._id}
                        objectType="deal"
                      />
                    </VirtualizedItem>
                  </div>
                )}
                {Boolean(allowProcessEmailBody && showEngagementData) && (
                  <div id="deal-body-topics-container">
                    <VirtualizedItem withClone>
                      <TopicsTabs
                        accountId={deal.data?.account_id}
                        dealId={deal.data?._id}
                        timespan={defaultTimeFilter}
                      />
                    </VirtualizedItem>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (
  state: IReduxState,
  { dealId, match }: IOwnProps
): IProps => ({
  user: selectors.getUser(state),
  deal: selectors.getDealDetailed(state, dealId || match?.params?.dealId),
  isAdmin: selectors.isAdmin(state),
  isMulticurrencyEnabled: selectors.isMulticurrencyEnabled(state),
  previousRoute: selectors.getPreviousRoute(state),
  hiddenColumns: selectors.getHiddenColumns(state),
  companyCurrency: selectors.getUserLocalCurrency(state),
  defaultTimeFilter: selectors.getDealInsightsDefaultTime(state),
  remapActivityStatus: state.activity.remapActivity,
  extraColumns: selectors.getCompanyColumnPreferences(state),
  editableFields: selectors.getEditableFields(state),
  allowProcessEmailBody: selectors.getAllowProcessEmailBody(state),
  featureFlags: selectors.getFeatureFlags(state),
  statuses: selectors.getDealStatuses(state),
  isAdminSFDCUpdateAllowed: selectors.getIsAdminSFDCUpdateAllowed(state),
  frozenTime: selectors.getFrozenTime(state),
  isReadOnlyUser: selectors.isReadOnlyUser(state),
  isAdminEnabled: selectors.isAdminEnabled(state),
  dealsInsightsColumns: selectors.getDealsInsightsColumns(state),
});

export default connect(mapStateToProps)(withRouter(DealInsights));
