import React from 'react';
import classNames from 'classnames';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import { Feedback } from 'contexts/AIChatContext';

import * as s from './styles';

export interface AIFeedbackProps {
  /** Current feedback state */
  feedback?: {
    sentiment?: Feedback;
  };
  /** Function to call when feedback button is clicked */
  onFeedback: (feedback: Feedback) => void;
  /** Whether feedback is currently being sent */
  isSending?: boolean;
  /** Optional additional class name */
  className?: string;
}

const AIFeedback: React.FC<AIFeedbackProps> = ({
  feedback,
  onFeedback,
  isSending = false,
  className,
}) => {
  const handlePositiveFeedback = () => {
    onFeedback(feedback?.sentiment === 'positive' ? 'neutral' : 'positive');
  };

  const handleNegativeFeedback = () => {
    onFeedback(feedback?.sentiment === 'negative' ? 'neutral' : 'negative');
  };

  return (
    <div
      className={classNames(
        s.feedbackContainer,
        {
          disabled: isSending,
        },
        className
      )}
    >
      <BuButton
        secondary
        borderless
        disabled={isSending}
        onClick={handlePositiveFeedback}
        className={classNames(
          {
            active: feedback?.sentiment === 'positive',
          },
          feedback?.sentiment
        )}
      >
        <BuIcon name={BoostUpIcons.ThumbsUp} />
      </BuButton>

      <BuButton
        secondary
        borderless
        disabled={isSending}
        onClick={handleNegativeFeedback}
        className={classNames(
          {
            active: feedback?.sentiment === 'negative',
          },
          feedback?.sentiment
        )}
      >
        <BuIcon name={BoostUpIcons.ThumbsDown} />
      </BuButton>
    </div>
  );
};

export default AIFeedback;
