import { Deal } from 'components/UI/DealsFlatTableConfig/types';
import axiosInstance from './axiosInstance';
import { GetItemsTotalsForMetricObjectResponseData } from './MetricObjectItems';

const GET_DEALS_URL = '/api/data/deals/';
const GET_TOTALS_DEALS_URL = '/api/data/deals/totals';
const GET_DEAL_SUMMARY_URL = (dealId: string) =>
  `/api/moonraker/opportunities/${dealId}/summary`;

export interface GetDealsParams {
  /**
   *  Ids of the deals to retrieve
   * */
  ids?: string[];
  only_included_deals?: boolean;
  skip_business_validation?: boolean;
  business_type_name?: string | null;

  page_number?: number;
  page_size?: number;
  sort?: string;
}

export interface GetDealsResponse {
  changes_since?: string;
  count: number;
  deals: Deal[];
  total_amount: number;
}

interface GetDealsResponseData {
  data: GetDealsResponse;
}

export const getDeals = async (request: GetDealsParams) => {
  const response = await axiosInstance.post<GetDealsResponseData>(
    GET_DEALS_URL,
    request
  );

  return response.data.data;
};

type UpdateDealOrigin = 'revbi' | '';

const getUpdateDealUrl = (dealId: string, origin: UpdateDealOrigin) =>
  `/api/data/deals/update/${dealId}/?origin=${origin}`;

export interface UpdateDealsParams {
  editedFields: Partial<Deal>;
  id: string;
  origin: UpdateDealOrigin;
}
export const updateDeal = async (params: UpdateDealsParams) => {
  const response = await axiosInstance.post<Deal>(
    getUpdateDealUrl(params.id, params.origin),
    params.editedFields
  );

  return response.data;
};

export const getDealsTotal = async (request: GetDealsParams) => {
  const response =
    await axiosInstance.post<GetItemsTotalsForMetricObjectResponseData>(
      GET_TOTALS_DEALS_URL,
      request
    );

  return response.data.data;
};

export interface GetDealSummaryResponse {
  opportunity_id: string;
  long_summary: string;
  short_summary: string;
  next_steps: string[];
  stale: boolean;
  last_updated: string;
}

interface GetDealSummaryParams {
  refresh?: boolean;
}

export const getDealSummary = async (
  dealId: string,
  params?: GetDealSummaryParams
): Promise<GetDealSummaryResponse> => {
  const response = await axiosInstance.post<GetDealSummaryResponse>(
    GET_DEAL_SUMMARY_URL(dealId),
    params
  );

  return response.data;
};
