import { css } from 'emotion';

export const toggleContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const toggle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  height: 20px;
`;

export const toggleButton = css`
  border: 1px solid var(--bu-primary-500);
  border-radius: 15px;
  background: white;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 12px;
  color: var(--bu-primary-500);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: all 0.2s ease;

  &:hover {
    background-color: var(--bu-gray-300);
  }
`;
