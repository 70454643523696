import { FC } from 'react';
import BuSkeleton from 'components/UI/BuSkeleton';
import { summary } from './styles';

interface Props {
  isLong?: boolean;
}

const DealSummaryCardSkeleton: FC<Props> = ({ isLong }) => {
  return (
    <>
      <div className={summary} style={{ width: '100%' }}>
        <div style={{ marginBottom: '24px' }}>
          <BuSkeleton height={10} />
          <BuSkeleton height={10} />
          <BuSkeleton height={10} />
        </div>
        {isLong && (
          <>
            <div style={{ marginBottom: '24px' }}>
              <BuSkeleton height={10} />
              <BuSkeleton height={10} />
              <BuSkeleton height={10} />
            </div>

            <div style={{ marginBottom: '24px' }}>
              <BuSkeleton height={10} />
              <BuSkeleton height={10} />
              <BuSkeleton height={10} />
            </div>

            <div style={{ marginBottom: '24px' }}>
              <BuSkeleton height={10} />
              <BuSkeleton height={10} />
              <BuSkeleton height={10} />
            </div>

            <div>
              <BuSkeleton height={10} />
              <BuSkeleton height={10} />
              <BuSkeleton height={10} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DealSummaryCardSkeleton;
