import { css } from 'emotion';

export const container = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  margin: 0 20px 20px 20px;
`;

export const field = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const label = css`
  font-family: var(--bu-font-regular);
  font-size: 12px;
  line-height: 120%;
  color: var(--bu-gray-900);
`;

export const value = css`
  font-family: var(--bu-font-semi-bold);
  font-size: 16px;
  line-height: 20px;
  color: var(--bu-gray-900);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const linkValue = css`
  font-family: var(--bu-font-semi-bold);
  font-size: 16px;
  line-height: 20px;
  color: var(--bu-primary-700);
  cursor: pointer;
`;

export const riskBadge = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 4px;
  border-radius: 4px;
  color: var(--bu-white);
  font-family: var(--bu-font-medium);
  font-size: 12px;
  line-height: 130%;
  min-width: 34px;
  height: 20px;
`;

export const riskBadgeHigh = css`
  ${riskBadge};
  background: var(--bu-red-600);
  border: 1px solid var(--bu-red-600);
`;

export const riskBadgeMediumHigh = css`
  ${riskBadge};
  background: var(--bu-orange-500);
  border: 1px solid var(--bu-orange-500);
`;

export const riskBadgeMedium = css`
  ${riskBadge};
  background: var(--bu-yellow-500);
  border: 1px solid var(--bu-yellow-500);
`;

export const riskBadgeLow = css`
  ${riskBadge};
  background: var(--bu-green-600);
  border: 1px solid var(--bu-green-600);
`;
